import { useState } from "react";
import useSideEffectOnMount from "./useSideEffectOnMount";

export default function useMounted() {
  const [mounted, setMounted] = useState(false);

  useSideEffectOnMount(() => {
    setMounted(true);
    return () => setMounted(false);
  });

  return mounted;
}
